<template>
  <div class="layout-fullscreen">
    <div class="w-100" id="animation"></div>
  </div>
</template>

<script lang="ts">
//import Vue from 'vue'
import * as THREE from "three";
const TWEEN = require("@tweenjs/tween.js");
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

// import shaders

import vertexShader from "../shaders/vertex.glsl.js";
import fragmentShader from "../shaders/fragment.glsl.js";

export default {
  metaInfo() {
    return {
      title: "Welcome",
    };
  },

  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      logo: null,
      monitor: null,
      controls: null,
      elements: [],
      rotationspeeds: [],
      images: [],
      resetPosition: null,
      messy: false,
      textures: [],
      pictures: [],
      running: true,
      stars: [],
      radius: 10,
      numberOfStars: 100,
      numberOfImages: 10,
      imagePlacement: 0,
      distanceToBack: 15,
      distanceToFront: 3,
      spawnRate: 80,
      spawnTimer: 0,
      spawnIndex: 0,
      time: 0,
      uniforms: [],
      imagesLoaded: false,
      monitors: [],
    };
  },
  methods: {
    /* Spawn items based on a timer so we don't have to worry about speed 
        and we can just add items to the scene */

    spawn() {
      this.spawnTimer += 1;
      if (this.spawnTimer > this.spawnRate && this.monitors.length > 0) {
        this.spawnTimer = 0;

        // Get the next image to be spawned
        if (this.spawnIndex > this.monitors.length - 1) this.spawnIndex = 0;

        const image = this.monitors[this.spawnIndex].object;

        const degrees = ((Math.PI * 2) / this.numberOfImages) * this.imagePlacement;
        const circleRadius = 4;
        image.position.x = Math.sin(degrees) * circleRadius;
        image.position.y = Math.cos(degrees) * circleRadius;
       image.position.z = this.distanceToFront;
        image.rotation.x = Math.PI;
        this.imagePlacement++;
        this.spawnIndex++;
      }
    },

    init: function () {
      let container = document.getElementById("animation");
      if (!container) return;
      this.camera = new THREE.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.01,
        40
      );
      this.camera.position.z = 4;
      this.scene = new THREE.Scene();

      this.placeLights();
      this.placeStars();
      this.placeLogo();

      // Load 3D model for monitor
      var loader = new GLTFLoader();
      var that = this;
      loader.load("/static/3d/monitor/monitor.glb", function (model) {
        that.monitor = model.scene;
        that.placeMonitors();
      });

      // Rendering
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.controls.enableDamping = true;

      this.controls.maxDistance = 7;
      this.controls.minDistance = 1;

      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);
      this.renderer.domElement.addEventListener(
        "dblclick",
        () => {
          this.messy = !this.messy;
          this.controls.enabled = this.messy;
          //   window.console.log(this.messy);
          if (!this.messy) {
            new TWEEN.Tween(this.camera.position)
              .to(this.resetPosition, 3600)
              .easing(TWEEN.Easing.Quadratic.Out)
              .start();
          }
        },
        true
      );
    },

    placeLights() {
      this.scene.add(new THREE.AmbientLight(0xffffff, 0.3));

      //   var light = new THREE.HemisphereLight(0xbbbbff, 0x444422);
      var dirLight = new THREE.SpotLight(0xffffff, 6, 5);
      dirLight.position.set(1.7, 1.3, 1.2);
      dirLight.lookAt(0, 0, 0);
      dirLight.castShadow = true;

      //Set up shadow properties for the light
      dirLight.shadow.mapSize.width = 1024; // default
      dirLight.shadow.mapSize.height = 2048; // default
      //dirLight.shadow.normalBias = 0.003
      dirLight.shadow.bias = -0.01;
      let someValue = 0.1;
      dirLight.shadow.camera.left = someValue;
      dirLight.shadow.camera.right = someValue;
      dirLight.shadow.camera.top = someValue;
      dirLight.shadow.camera.bottom = someValue;

      dirLight.shadow.camera.far = 2;
      dirLight.shadow.camera.near = 1;

      this.scene.add(dirLight);
    },

    placeLogo() {
      var that = this;
      var loader = new GLTFLoader();

      loader.load(
        "/static/3d/logo.glb",
        function (model) {
          that.logo = model.scene;
          let i = 0;
          that.logo.traverse(function (child) {
            i++;
            if (child instanceof THREE.Mesh) {
              that.elements.push(child);
              that.rotationspeeds.push([
                Math.random() / 100,
                Math.random() / 100,
                Math.random() / 100,
              ]);
              if (child.isMesh && i > 0) child.castShadow = true;
              if (child.isMesh || child.isLight) child.receiveShadow = true;
            }
          });
          that.logo.scale.set(0.2, 0.2, 0.2);
          that.logo.rotation.x += 2;
          that.scene.add(that.logo);
        }
        // function (xhr) {
        //  // console.log(xhr.loaded);
        // }
      );
    },

    placeStars() {
      var geometry = new THREE.SphereGeometry(0.01, 32, 32);
      var material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
      });
      // The loop will move from z position of radios to z position 1000, adding a random particle at each position.
      for (
        var z = -this.distanceToFront;
        z < this.distanceToBack;
        z += (this.distanceToBack + this.distanceToFront) / this.numberOfStars
      ) {
        // Make a sphere (exactly the same as before).

        var sphere = new THREE.Mesh(geometry, material);

        // This time we give the sphere random x and y positions between -500 and 500
        sphere.position.x = Math.random() * this.radius - this.radius / 2;
        sphere.position.y = Math.random() * this.radius - this.radius / 2;

        // Then set the z position to where it is in the loop (distance of camera)
        sphere.position.z = z;

        // scale it up a bit
        sphere.scale.x = sphere.scale.y = 2;

        //add the sphere to the scene
        this.scene.add(sphere);

        //finally push it to the stars array
        this.stars.push(sphere);
      }
    },

    animateStars() {
      for (var i = 0; i < this.stars.length; i++) {
        this.stars[i].position.z -= i / 7000;

        // if the particle is too close move it to the back
        if (this.stars[i].position.z < -this.distanceToBack)
          this.stars[i].position.z = this.distanceToFront;
      }
    },

    placeMonitor: function (id) {
      const monitorGroup = new THREE.Group();
      const planeGeometry = new THREE.PlaneGeometry(0.94 * 1.3, 0.64 * 1.3);
      const texture = new THREE.TextureLoader().load(
        "/data/images/project/slides/slide-" + id.toString() + "full.jpg"
      );
      var uniform = {
        time: {
          type: "f",
          value: 1,
        },
        resolution: {
          type: "v2",
          value: new THREE.Vector2(960 / 2, 640 / 2),
        },
        texture2: {
          type: "t",
          value: texture,
        },
      };

      const shaderMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        uniforms: uniform,
      });

      const image = new THREE.Mesh(planeGeometry, shaderMaterial);
      monitorGroup.rotation.x = Math.PI; // Hide images initially
        monitorGroup.position.z = -4;//S0;
      this.monitor.position.y = -0.5;
      image.position.y = 0.1;
      image.position.z = 0.1;
      monitorGroup.add(image);
      monitorGroup.add(this.monitor.clone());
      this.scene.add(monitorGroup);

      return {
        object: monitorGroup,
        uniform: uniform,
      };
    },

    placeMonitors: function () {
      // Image
      //var planeGeometry = new THREE.PlaneGeometry(0.94 * 1.5, 0.64 * 1.5);

      const projects = [
        //67, 49, 82,
        // oud
        65, 212, 206, 105, 214, 219, 108, 204, 113, 218, 120, 123, 129, 187,
        135, 143, 136, 154, 158, 161, 197, 183, 177, 167, 96,
      ];
      projects.forEach((projectId) => {
        this.monitors.push(this.placeMonitor(projectId));
      });
    //     this.textures.push(
    //       new THREE.TextureLoader().load(
    //         //"static/assets/images/test.png"
    //         "/data/images/project/slides/slide-" + projectId + "full.jpg"
    //       )
    //     );

    //     this.uniforms.push({
    //       time: {
    //         type: "f",
    //         value: 1,
    //       },
    //       resolution: {
    //         type: "v2",
    //         value: new THREE.Vector2(960 / 2, 640 / 2),
    //       },
    //       texture2: {
    //         type: "t",
    //         value: this.textures[this.textures.length - 1],
    //       },
    //     });
    //     //  this.scene.add(monitorGroup);
    //   });

    //   for (
    //     var textureIndex = 0;
    //     textureIndex < this.textures.length;
    //     textureIndex++
    //   )
    //     this.pictures[textureIndex] = new THREE.ShaderMaterial({
    //       vertexShader: vertexShader,
    //       fragmentShader: fragmentShader,
    //       uniforms: this.uniforms[textureIndex],
    //     });

    //   for (var i = 0; i < this.numberOfImages; i++) {
    //     this.images[i] = new THREE.Mesh(
    //       planeGeometry,
    //       this.pictures[i % this.textures.length]
    //     );
    //     this.images[i].rotation.x = Math.PI; // Hide images initially
    //     this.images[i].position.z = -40;
    //     //        this.placeRandom(this.images[i]);
    //     this.scene.add(this.images[i]);
//      }

      this.imagesLoaded = true;
    },

    animateMonitors() {
        for (
        var textureIndex = 0;
        textureIndex < this.monitors.length;
        textureIndex++
      ) {
         this.monitors[textureIndex].uniform.time.value += 0.01 + 100 * textureIndex;
      }


      for (var j = 0; j < this.monitors.length; j++) {
         const speed = 0.01;
         if (this.monitors[j].object.rotation.x > 0) {
           this.monitors[j].object.rotation.x -= speed * 1;
        }

//        this.monitors[j].object.rotation.y += 0. 001
        this.monitors[j].object.position.z -=
          (speed *
            (this.monitors[j].object.position.z -
              this.distanceToFront -
              this.distanceToBack)) /
          (this.distanceToFront - this.distanceToBack);
       this.monitors[j].object.rotation.z +=
          0.0003 * (this.monitors[j].object.position.z + this.distanceToBack);
        this.monitors[j].object.scale.y =
          -(this.monitors[j].object.position.z + this.distanceToBack) / 6;
        this.monitors[j].object.scale.x =
          -(this.monitors[j].object.position.z + this.distanceToBack) / 6;
       }
    },
    animate: function () {
      TWEEN.update();
      this.controls.update();
      if (this.imagesLoaded) {
        this.spawn();
        this.animateMonitors();
      }
      // if (this.image.position.z < -10) this.placeRandom(this.image)
      // if (this.image2.position.z < -10) this.placeRandom(this.image2)

      this.animateStars();

      if (this.running) requestAnimationFrame(this.animate);
      if (this.logo !== null) {
        this.logo.rotation.z = Math.sin(new Date().getTime() / 3000) / 8;
        this.logo.rotation.x = 1.8 + Math.cos(new Date().getTime() / 2000) / 8;
        this.logo.rotation.y = Math.cos(new Date().getTime() / 34000) / 8;

        if (this.messy) {
          this.logo.rotation.z += 0.004;
        } else {
          this.logo.rotation.z -= this.logo.rotation.z / 50;
        }
        for (var i = 0; i < this.elements.length; i++) {
          if (this.elements[i].rotation.x > Math.PI * 2)
            this.elements[i].rotation.x = 0;
          if (this.elements[i].rotation.y > Math.PI * 2)
            this.elements[i].rotation.y = 0;
          if (this.elements[i].rotation.z > Math.PI * 2)
            this.elements[i].rotation.z = 0;
          if (this.messy) {
            this.elements[i].rotation.x += this.rotationspeeds[i][0];
            this.elements[i].rotation.y += this.rotationspeeds[i][1];
            this.elements[i].rotation.z += this.rotationspeeds[i][2];
          } else {
            this.elements[i].rotation.x -= this.elements[i].rotation.x / 50;
            this.elements[i].rotation.y -= this.elements[i].rotation.y / 50;
            this.elements[i].rotation.z -= this.elements[i].rotation.z / 50;
          }
        }

        this.controls.update();
      }

      this.renderer.render(this.scene, this.camera);
    },
  },
  mounted() {
    this.init();
    this.animate();
  },
  beforeDestroy() {
    this.running = false;
  },
};
</script>

<style lang="scss" scoped></style>
